import React, { Component } from 'react';
import { getRichTextHtml } from '@msdyn365-commerce/core';
import { ILobasArticleCollectionViewProps } from '../lobas-article-collection';
import { ILobasArticleCollectionMiniViewProps } from '../../lobas-article-collection-mini/lobas-article-collection-mini';

class ArticleCollectionCard extends Component<ILobasArticleCollectionViewProps | ILobasArticleCollectionMiniViewProps> {
    private fixDateFormat(date: Date | undefined): String {
        if (!date) {
            return '';
        }

        const length = 10;
        return new Date(date).toLocaleDateString(this.props.locale).substring(0, length);
    }

    public render() {
        return (
            <div className='articleCollectionCard'>
                <img className='articleCollectionCardThumbnail' src={this.props.article.ThumbnailImage}></img>
                <h2 className='articleCollectionCardTitle'>{this.props.article.Title}</h2>
                <div className='articleCollectionCardDate'>{`${this.props.resources.articlePublishedDate} ${this.fixDateFormat(
                    this.props.article.DateReleased
                )}`}</div>
                <h3 className='articleCollectionCardSummary' dangerouslySetInnerHTML={getRichTextHtml(this.props.article.Summary)}></h3>
                <div className='articleCollectionCardKeywordsContainer'>
                    {this.props.article.Keywords?.split(',').map(kw => (
                        // eslint-disable-next-line react/jsx-key
                        <h4 className='articleCollectionCardKeyword'>{kw}</h4>
                    ))}
                </div>
                <div className='articleCollectionCardReadMore'>{this.props.resources.readMoreLabel}</div>
            </div>
        );
    }
}

export default ArticleCollectionCard;
